import { Flex, Progress, useDisclosure } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { AlertModal } from '../../../../modals/AlertModal'

import { StopCardHeader } from './components/StopCardHeader'
import { StopRealTimeInfo } from './components/StopRealTimeInfo'
import { StopRoutesList } from './components/StopRoutesList'
import { StopUtilitiesInfo } from './components/StopUtilitiesInfo'
import { useNavigate } from 'react-router-dom'

function StopCard({
  network,
  selectedNetwork,
  selectedStop,
  updateSelectedRoute,
  handleHoverRoute,
  openSchedules,
  closeDrawer,
}) {
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [stop, setStop] = useState(null)
  const [routesInStop, setRoutesInStop] = useState([])
  const [alertsExpanded, expandAlerts] = useState(false)
  const [selectedAlert, selectAlert] = useState(null)
  const [alerts, setAlerts] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    expandAlerts(false)
  }, [selectedStop])

  useEffect(() => {
    const filteredAlerts = new Set()
    const filteredRoutes = []

    selectedNetwork?.alerts.forEach(alert => {
      if (alert?.affectedStops) {
        const hasAffectedStop = alert.affectedStops.some(stop => stop.stopID === selectedStop)

        if (hasAffectedStop) {
          filteredAlerts.add(alert)
        }
      }
    })

    const filteredAlertsArray = Array.from(filteredAlerts)
    let stop = selectedNetwork?.stops.find(stop => stop.id === selectedStop) || null
    while (!stop) {
      stop = selectedNetwork?.stops.find(stop => stop.id === selectedStop) || null
    }
    const routes = JSON.parse(stop?.routeIDs)
    routes.forEach(routeID => {
      filteredRoutes.push(network.routes.find(route => route.id === routeID))
    })

    setStop(stop)
    setRoutesInStop(filteredRoutes.sort((a, b) => a.shortName.localeCompare(b.shortName, undefined, { numeric: true })))
    setAlerts(filteredAlertsArray)
  }, [selectedStop])

  function handleAlert(alert) {
    selectAlert(alert)
    onOpen()
  }

  function handleClick(routeID, routeName) {
    setLoading(true)
    updateSelectedRoute(routeID)
    navigate(`/network-and-schedules/route/${routeID}/0`)
  }

  return (
    <Flex
      direction='column'
      gap='24px'
      padding={{ sm: '16px 0', lg: '16px' }}
      border={{ lg: '1px solid #CACFD8' }}
      bg='white'
      boxShadow={{ lg: '0px 2px 20px 1px rgba(0, 0, 0, 0.1)' }}
      borderRadius={{ lg: '8px' }}
    >
      {loading && <Progress size='xs' mt='-16px' ml='-16px' mr='-16px' isIndeterminate borderRadius='64px 64px 0 0' />}

      <StopCardHeader
        selectedNetwork={selectedNetwork}
        alertValue={alerts}
        alertsExpanded={alertsExpanded}
        expandAlerts={expandAlerts}
        stop={stop}
        loading={loading}
        handleAlert={handleAlert}
        handleSchedules={openSchedules}
        closeDrawer={closeDrawer}
      />

      {/*<StopRealTimeInfo selectedNetwork={selectedNetwork} selectedStop={selectedStop} />*/}

      <StopRoutesList routesInStop={routesInStop} handleClick={handleClick} handleHoverRoute={handleHoverRoute} />

      <StopUtilitiesInfo selectedNetwork={selectedNetwork} />

      <AlertModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        alert={selectedAlert}
        network={selectedNetwork}
        type={0}
      />
    </Flex>
  )
}

export default StopCard
