import {
  Column,
  Button, InlineNotification, Card, Text, Label, Chip,
} from '@ubirider/pick-component-library'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import { UserProfileInfo } from '../UserProfileInfo'
import { Flex } from '@chakra-ui/react'
import { ProfileDecision, ProfileStatus, ProfileStatusLabel, ProfileStatusType } from '../../../../consts/status'

export const ActionUserProfile = ({ userProfile, handleRenewProfile, handleChangeProfile, handleNewProfile, handleScroll, onClose }) => {
  const { t } = useTranslation()

  const [mostRecentProfile, additionalProfile, withinRenewalPeriod] = useMemo(() => {
    const expiration = DateTime.fromISO(userProfile?.profiles[0].expiryDate, { zone: 'utc' }).toLocal()
    const renewal = DateTime.fromISO(userProfile?.profiles[0].renewalDate, { zone: 'utc' }).toLocal()
    const renewalCheck = DateTime.now() >= renewal && DateTime.now() <= expiration

    return [userProfile.profiles[0], userProfile?.profiles[1], renewalCheck]
  },[userProfile])

  return (
    <Flex
      w='100%'
      direction='column'
      borderRadius={{ lg: '0 0 8px 8px' }}
      flex='1'
    >
      <Flex
        direction='column'
        gap='24px'
        padding={{ sm: '16px 32px 32px', lg: '12px 32px 32px' }}
        bg="white"
        w='100%'
        h='100%'
        overflowY='none'
        onScroll={handleScroll}
      >
        {mostRecentProfile.status === ProfileStatus.PENDING && userProfile.profiles.length === 2 && additionalProfile.status < ProfileStatus.DEACTIVATED ?
          <>
          <Card
            css={{
              display: 'flex',
              flexDirection: 'column',
              gap: 24,
              padding: 24,
              backgroundColor: '$primary50',
              border: '1px solid $primary100',
              boxShadow: '0px 2px 20px 1px rgba(0, 0, 0, 0.10)'
            }}
          >
            <Flex direction={{ sm: 'column', lg: 'row' }} gap={{ sm: '24px', lg: 0 }} justifyContent='space-between'>
              <Column css={{ gap: 4 }}>
                <Text type='textSM' weight='semibold' css={{ color: '$neutral500' }}>
                  {t('ticket_fares.new_profile')}
                </Text>
                <Chip boldLabel={mostRecentProfile.profileName} />
              </Column>

              <Column css={{ gap: 24 }}>
                <Column css={{ gap: 4 }}>
                  <Text type='textSM' weight='semibold' css={{ color: '$neutral500' }}>
                    {t('ticket_fares.request_date')}
                  </Text>
                  <Text type='textMD' weight='semibold'>
                    {DateTime.fromISO(mostRecentProfile.requestDate).toFormat('dd/MM/yyyy')}
                  </Text>
                </Column>
              </Column>

              <Column css={{ gap: 24, justifyContent: 'flex-end' }}>
                <Column css={{ gap: 4 }}>
                  <Text type='textSM' weight='semibold' css={{ color: '$neutral500' }}>
                    {t('network_schedule.status')}
                  </Text>
                  <Label
                    label={t(ProfileStatusLabel[mostRecentProfile.status])}
                    type={ProfileStatusType[mostRecentProfile.status]}
                    style={'outline'}
                  />
                </Column>
              </Column>
            </Flex>

            {mostRecentProfile.status === ProfileStatus.PENDING && <InlineNotification type={'warning'} message={t('ticket_fares.profile_pending_approval')} />}
          </Card>

          <UserProfileInfo userProfile={{...additionalProfile, ...userProfile}} showInline={true} />
          </>
          :
          <UserProfileInfo userProfile={{...mostRecentProfile, ...userProfile}} showInline={true} />
        }

        {additionalProfile &&
          <Card
            css={{
              display: 'flex',
              flexDirection: 'column',
              gap: 24,
              padding: 24,
              backgroundColor: '$neutral200',
              border: '1px solid $neutral300',
              boxShadow: '0px 2px 20px 1px rgba(0, 0, 0, 0.10)'
            }}
          >
            <Flex direction={{ sm: 'column', lg: 'row' }} gap={{ sm: '24px', lg: 0 }} justifyContent='space-between'>
              <Column css={{ gap: 4 }}>
                <Text type='textSM' weight='semibold' css={{ color: '$neutral500' }}>
                  {t('ticket_fares.previous_profile')}
                </Text>
                <Chip boldLabel={additionalProfile.profileName} />
              </Column>

              <Column css={{ gap: 24 }}>
                <Column css={{ gap: 4 }}>
                  <Text type='textSM' weight='semibold' css={{ color: '$neutral500' }}>
                    {t('ticket_fares.validity')}
                  </Text>
                  <Text type='textMD' weight='semibold'>
                    {additionalProfile.expiryDate ? DateTime.fromISO(additionalProfile.expiryDate).toFormat('dd/MM/yyyy') : '-'}
                  </Text>
                </Column>
              </Column>

              <Column css={{ gap: 24, justifyContent: 'flex-end', mt: additionalProfile.status === ProfileStatus.DEACTIVATED ? -24: undefined }}>
                <Column css={{ gap: 4 }}>
                  <Text type='textSM' weight='semibold' css={{ color: '$neutral500' }}>
                    {t('network_schedule.status')}
                  </Text>
                  <Label
                    label={t(ProfileStatusLabel[additionalProfile.status])}
                    type={ProfileStatusType[additionalProfile.status]}
                    style={'outline'}
                  />
                </Column>
              </Column>
            </Flex>

            {additionalProfile.status === ProfileStatus.PENDING && <InlineNotification type={'warning'} message={t('ticket_fares.profile_pending_approval')} />}
            {additionalProfile.status === ProfileStatus.DEACTIVATED && additionalProfile?.decision === ProfileDecision.DECLINED && <InlineNotification type={'warning'} message={t('ticket_fares.profile_request_declined')} />}
          </Card>
        }
      </Flex>

        <Flex
          position='relative'
          align='flex-end'
          justify='flex-end'
          gap='20px'
          w="100%"
          padding='0 32px 32px'
          bg={'white'}
          borderRadius={{ lg: '0 0 8px 8px' }}
          transition='background-color 0.3s ease, box-shadow 0.3s ease'
        >
          <Button
            label={mostRecentProfile.status > ProfileStatus.PENDING ? t('ticket_fares.request_new_profile') : t('common.ok')}
            type={withinRenewalPeriod ? 'neutralLight' : 'primary'}
            style={'solid'}
            onClick={mostRecentProfile.status === ProfileStatus.PENDING ? onClose : withinRenewalPeriod ? handleChangeProfile : handleNewProfile}
            css={{ minWidth: 128 }}
          />
          {withinRenewalPeriod && mostRecentProfile.status < ProfileStatus.DEACTIVATED &&
            <Button
              label={t('ticket_fares.renew_profile')}
              type={'primary'}
              style={'solid'}
              onClick={handleRenewProfile}
              css={{ minWidth: 128 }}
            />
          }
        </Flex>
    </Flex>
  )
}
