import { Box, Flex, Image, Text, useToast } from '@chakra-ui/react'
import { useRef } from 'react'
import checkWhiteIcon from '../assets/check_circle_white.svg'
import closeWhiteIcon from '../assets/cross_white.svg'
import alertWhiteIcon from '../assets/alert_white.svg'

export const useCustomToast = () => {
  const toast = useToast()
  const toastIdRef = useRef()

  return (type, message) => {
    toastIdRef.current = toast({
      position: 'top',
      duration: 5000,
      isClosable: true,
      render: () => (
        <Box
          alignItems='center'
          color='white'
          bg={type === 'success' ? '#01AE70' : '#E8554F'}
          border={type === 'success' ? '1px solid #067F5B' : '1px solid #D23636'}
          boxShadow='box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.05)'
          borderRadius='4px'
        >
          <Flex justifyContent='space-between' gap='16px' padding='16px'>
            <Image src={type === 'success' ? checkWhiteIcon : alertWhiteIcon} />
            <Text fontSize='14px' fontWeight='600'>
              {message}
            </Text>
            <Image
              cursor='pointer'
              onClick={() => toast.close(toastIdRef.current)}
              src={closeWhiteIcon}
            />
          </Flex>
        </Box>
      ),
    })
  }
}
