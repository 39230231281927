import {
  Button,
  FormItem,FileInput,
} from '@ubirider/pick-component-library'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { Flex } from '@chakra-ui/react'
import config from '../../../../config/configLoader'
import { RequirementTemplates } from '../../../../consts/templates'
import { UserProfileInfo } from '../UserProfileInfo'
import { handleChangeProfile } from '../../../../api/requests'
import { useCustomToast } from '../../../../hooks/useToast'

export const RenewUserProfile = ({ userProfile, setLoading, loading, handleScroll, isScrolledToBottom, onClose }) => {
  const { t } = useTranslation()
  const showCustomToast = useCustomToast()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useFormContext()

  const onSubmitRenewProfile = async data => {
    setLoading(true)
    try {
      const docFiles = []
      if (userProfile.profiles[0].requiredDocs.length > 1) userProfile.profiles[0].requiredDocs.forEach(doc => docFiles.push(data[doc]))
      await handleChangeProfile({
        customerID: userProfile.customerID,
        profileID: null,
      }, docFiles)
      showCustomToast('success', t("ticket_fares.profile_successfully_submitted"))
      onClose()
    } catch (e) {
      console.log(e)
      showCustomToast('danger', t("ticket_fares.profile_request_declined"))
    }
    setLoading(false)
  }

  return (
    <Flex
      as='form'
      onSubmit={handleSubmit(onSubmitRenewProfile)}
      w='100%'
      direction='column'
      borderRadius={{ lg: '0 0 8px 8px' }}
      flex='1'
    >
      <Flex
        direction='column'
        gap='24px'
        bg="white"
        w='100%'
        padding={{ sm: '16px 32px 32px', lg: '12px 32px 16px 32px' }}
        h={{ lg: 'calc(100vh - 344px)' }}
        overflowY={{ lg: 'scroll' }}
        onScroll={handleScroll}
      >
        <UserProfileInfo userProfile={{...userProfile.profiles[0], ...userProfile}} showInline={false} />

        <Flex direction='column' gap='24px' width='100%'>
          {userProfile.profiles[0].requiredDocs?.map(document => (
            <Controller
              name={document}
              control={control}
              rules={{ required: t('authentication.required_field') }}
              render={({ field }) => (
                <FormItem required label={document} link={RequirementTemplates[config.operator][document]?.link ?? null} error={errors[document]}>
                  <FileInput
                    {...field}
                    maxSize={2}
                    expanded={false}
                    multiple={false}
                    allowedExtensions={['pdf', 'png', 'jpeg', 'jpg']}
                  />
                </FormItem>
              )}
            />
          ))}
        </Flex>
      </Flex>

      <Flex
        position='relative'
        align='flex-end'
        justify='flex-end'
        w="100%"
        padding={{ sm: '0 32px 32px', lg: '16px 32px 32px 32px' }}
        bg={'white'}
        boxShadow={isScrolledToBottom ? { lg: 'none' } : { lg: '0px 2px 20px 1px rgba(0, 0, 0, 0.1)' }}
        borderRadius={{ lg: '0 0 8px 8px' }}
        transition='background-color 0.3s ease, box-shadow 0.3s ease'
      >
        <Button
          label={t('common.submit')}
          type={'primary'}
          style={'solid'}
          submit
          loading={loading}
          css={{ minWidth: 128 }}
        />
      </Flex>
    </Flex>
  )
}
