import { getBFFUrl, getVivaWalletSourceCode } from './api'
import axios from 'axios'
import config from '../config/configLoader'
import i18next from 'i18next'
import { v4 as uuidv4 } from 'uuid'

export const agencyID = config.agencyID
export const operatorID = config.operatorID

export const getNetworkInfo = async (endpoint, params) => {
  const res = await axios.post(
    `${getBFFUrl()}/network/getInfo`,
    {
      operatorID,
      endpoint: endpoint,
      params: params,
    },
    {
      responseType: 'json',
      crossDomain: true,
      signal: null,
    }
  )

  return res.data
}

export const getPrices = async () => {
  const res = await axios.post(
    `${getBFFUrl()}/ticketing/getPrices`,
    {
      operatorID,
      params: {
        agencyID: agencyID,
      },
    },
    {
      responseType: 'json',
      crossDomain: true,
      signal: null,
    }
  )

  return res.data
}

export const sendEmail = async (type, data) => {
  const { curriculum, presentation_letter, check, ...userData } = data
  const formData = new FormData()
  formData.append('operatorID', operatorID)
  formData.append(
    'params',
    JSON.stringify({
      template: 'customer_support',
      app: config.operator,
      recipient: type === 'recruitment' ? config.info.recruitment_email : config.info.support_email,
      attachment: type === 'recruitment' ? 'true' : 'false',
      data: {
        ...userData,
        saveData: data.saveData ? 'true' : 'false',
        name: encodeURIComponent(data.name),
        message: encodeURIComponent(data.message),
        type: type,
        origin: data?.origin ? encodeURIComponent(data.origin) : null,
        destination: data?.destination ? encodeURIComponent(data.destination) : null,
      },
    })
  )
  if (type === 'recruitment') {
    formData.append('curriculum', data?.curriculum)
    formData.append('presentation_letter', data?.presentation_letter)
  }

  const res = await axios.post(`${getBFFUrl()}/mails/sendEmail`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
    crossDomain: true,
  })

  return res.data
}

export const getCustomerProfile = async (idDocument, birthdate) => {
  const params = {
    agencyID,
    idDocument,
    birthdate,
  }

  const res = await axios.post(
    `${getBFFUrl()}/ticketing/getCustomerProfile`,
    {
      operatorID,
      params: params,
    },
    {
      responseType: 'json',
      crossDomain: true,
      signal: null,
    }
  )

  return res.data.data
}

export const validatePass = async (card_name, card_number) => {
  const params = {
    agencyID: agencyID,
    name: card_name,
    legacyCardNumber: card_number,
  }

  const res = await axios.post(
    `${getBFFUrl()}/ticketing/validatePass`,
    {
      operatorID,
      params: params,
    },
    {
      responseType: 'json',
      crossDomain: true,
      signal: null,
    }
  )

  return res.data
}

export const validateCustomer = async (card_option, card_id, tax_id, email) => {
  const params = {
    agencyID: agencyID,
    travelCardType: card_option === 'digital_card' ? '0' : '1',
    idDocument: card_id,
    taxNumber: tax_id,
    email: email,
  }

  const res = await axios.post(
    `${getBFFUrl()}/ticketing/validateCustomer`,
    {
      operatorID,
      params: params,
    },
    {
      responseType: 'json',
      crossDomain: true,
      signal: null,
    }
  )

  return res.data
}

export const getPaymentInfo = async data => {
  const params = {
    amount: data.total,
    customerTrns:
      data.cardOption
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ') +
      ' - ' +
      data.passType
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' '),
    customer: {
      email: data?.email,
      fullName: data?.name,
      phone: data?.phone,
      countryCode: 'PT',
      requestLang: i18next.language === 'PT' ? 'pt-PT' : 'en-US',
    },
    paymentTimeout: 720,
    preauth: false,
    allowRecurring: false,
    maxInstallments: 12,
    paymentNotification: false,
    tipAmount: 0,
    disableExactAmount: false,
    disableCash: true,
    disableWallet: true,
    sourceCode: getVivaWalletSourceCode(),
    merchantTrns:
      config.name +
      ': ' +
      data.cardOption
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ') +
      ' - ' +
      data.passType
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' '),
    tags: [config.name, data.passType, data.cardOption],
  }
  return await axios.post(
    `${getBFFUrl()}/payments/handlePayment`,
    {
      operatorID,
      params: params,
    },
    {
      responseType: 'json',
      crossDomain: true,
      signal: null,
    }
  )
}

export const handleCustomerCompleteSetup = async (data, attachments) => {
  const {
    postal_code,
    phone,
    card_id,
    tax_id,
    birthdate,
    city,
    cardOption,
    passType,
    total,
    profileID,
    travelCardID,
    photo,
    files,
    filesSize,
    ...userData
  } = data

  let photoPath = null
  let photoKey = null
  let attachmentsPath = null

  if (attachments.photo) {
    const photoUUID = uuidv4(undefined, undefined, undefined)
    photoPath = `customers/${photoUUID}`

    const formDataPhoto = new FormData()
    formDataPhoto.append('operatorID', operatorID)
    formDataPhoto.append('filePath', photoPath)
    formDataPhoto.append('photo', attachments.photo)

    const photoRes = await axios.post(`${getBFFUrl()}/ticketing/uploadFileToPath`, formDataPhoto, {
      headers: {
        'content-type': 'multipart/form-data',
      },
      crossDomain: true,
    })

    photoKey = photoRes.data.photoKey
  }

  if (attachments.files.length > 0) {
    const attachmentsUUID = uuidv4(undefined, undefined, undefined)
    attachmentsPath = `profiles/${attachmentsUUID}`

    for (const file of attachments.files) {
      const formDataAttachment = new FormData()
      formDataAttachment.append('operatorID', operatorID)
      formDataAttachment.append('filePath', attachmentsPath)
      formDataAttachment.append('attachment', file, encodeURIComponent(file.name))

      await axios.post(`${getBFFUrl()}/ticketing/uploadFileToPath`, formDataAttachment, {
        headers: {
          'content-type': 'multipart/form-data',
        },
        crossDomain: true,
      })
    }
  }

  const res = await axios.post(
    `${getBFFUrl()}/ticketing/createRequest`,
    { 
      operatorID,
      agencyID,
      profileID: data.profileID,
      travelCardID: data.travelCardID,
      travelCardType: data.cardOption === 'digital_card' ? 0 : 1,
      travelCardList: 1,
      attachmentsPath,
      customerData: {
        ...userData,
        idDocument: data?.card_id,
        phoneNumber: data?.phone,
        postalCode: data?.postal_code,
        taxNumber: data?.tax_id,
        birthdate: data?.birthdate,
        photoKey: photoKey,
      }
    },
    {
      responseType: 'json',
      crossDomain: true,
      signal: null,
    }
  )

  return res.data
}

export const handleChangeProfile = async (data, attachments) => {
  let attachmentsPath = null

  if (attachments.length > 0) {
    const attachmentsUUID = uuidv4(undefined, undefined, undefined)
    attachmentsPath = `profiles/${attachmentsUUID}`

    for (const file of attachments) {
      const formDataAttachment = new FormData()
      formDataAttachment.append('operatorID', operatorID)
      formDataAttachment.append('filePath', attachmentsPath)
      formDataAttachment.append('attachment', file, encodeURIComponent(file.name))

      await axios.post(`${getBFFUrl()}/ticketing/uploadFileToPath`, formDataAttachment, {
        headers: {
          'content-type': 'multipart/form-data',
        },
        crossDomain: true,
      })
    }
  }

  const res = await axios.post(`${getBFFUrl()}/ticketing/createCustomerProfile`,
    {
      operatorID,
      agencyID,
      customerID: data.customerID,
      profileID: data.profileID,
      attachmentsPath,
    },
    {
    responseType: 'json',
    crossDomain: true,
    signal: null,
  })

  return res.data
}

export const updateTicketingInfo = async (data, files) => {
  const {
    pass_name,
    pass_number,
    postal_code,
    phone,
    card_id,
    tax_id,
    birthdate,
    city,
    passType,
    profileID,
    profileName,
    travelCardRequestID,
    photo,
    ...userData
  } = data
  const filesNames = []
  files?.forEach(file => filesNames.push(file?.name))
  const formData = new FormData()
  formData.append('operatorID', operatorID)
  formData.append('agencyID', agencyID)
  formData.append(
    'params',
    JSON.stringify({
      ...userData,
      idDocument: data?.card_id,
      phoneNumber: data?.phone,
      postalCode: data?.postal_code,
      taxNumber: data?.tax_id,
      birthdate: new Date(data.birthdate).toISOString().split('T')[0],
    })
  )
  formData.append('profileID', data?.profileID)
  formData.append('travelCardRequestID', data.travelCardRequestID)
  formData.append('travelCardType', '0')
  formData.append('photo', data.photo)
  files?.forEach(file => formData.append('attachments', file))

  const res = await axios.post(`${getBFFUrl()}/ticketing/updateInfo`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
    crossDomain: true,
  })

  return res.data
}

export const createSale = async (data, ticketing, id) => {
  console.log(data, ticketing, id)
  const params = {
    agencyID: agencyID,
    travelCards: [
      {
        travelCardID: data.travelCardID,
        quantity: 1,
        travelCardType: data.cardOption === 'digital_card' ? 0 : 1,
      },
    ],
    salesChannel: 3,
    paymentMethod: 1,
    paymentGateway: 'vvw',
    transactionID: id,
    userID: null,
    userTravelCardID: ticketing.id,
    invoiceCustomer: {
      name: data.name,
      vat: data.tax_id,
      address: data.address,
      city: data.city,
      zipCode: data.postal_code,
      email: data.email,
    },
    app: 'pick',
  }

  console.log(params)

  const res = await axios.post(
    `${getBFFUrl()}/ticketing/createSale`,
    {
      operatorID,
      params: params,
    },
    {
      responseType: 'json',
      crossDomain: true,
      signal: null,
    }
  )

  return res.data
}
