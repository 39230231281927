import {
    Modal,
    ModalOverlay,
    ModalContent,
    Flex,
    Image,
    Text,
    Divider,
    Link,
    useDisclosure,
} from '@chakra-ui/react'
import {
    Button,
    FormItem,
    TextInput,
    validatePhoneNumber,
    PhoneNumberInput,
    Select,
    Checkbox,
    DateInput,
    FileInput,
    Avatar
} from "@ubirider/pick-component-library"
import React, {useEffect, useRef, useState} from "react"
import { useForm, Controller } from "react-hook-form"
import {Trans, useTranslation} from "react-i18next"
import {getPaymentInfo, validateCustomer} from "../../api/requests"
import {Link as ReactLink, useNavigate} from "react-router-dom"
import {TransactionErrorBanner} from "../../pages/Common/TransactionErrorBanner"
import {DateTime} from "luxon"
import {storeFileInDB} from "../../hooks/useIndexedDB"
import config from "../../config/configLoader"
import {errorIDs} from "../../consts/payment"
import {customError, customLog} from "../../helpers/log"
import { emailValidation, nifValidation } from "../../helpers/validation"
import {RequirementTemplates} from "../../consts/templates"
import Cookies from 'universal-cookie/es6'

import closeButton from "../../assets/cross.svg"
import alertIcon from "../../assets/alert_dark_yellow.svg"
import infoIconBlue from "../../assets/info_blue.svg"
import camera from "../../assets/camera_avatar.svg"
import { ErrorRequestModal } from '../ErrorRequestModal'

export function OrderPassModal(props) {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const { isOpen, onOpen, onClose } = useDisclosure()

    const initialRef = React.useRef(null)

    const cookies = new Cookies()

    const {
        control,
        reset,
        watch,
        handleSubmit,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm({ defaultValues: {
            name: props.dataLS?.name,
            address: props.dataLS?.address,
            postal_code: props.dataLS?.postal_code,
            city: props.dataLS?.city,
            email: props.dataLS?.email,
            phone: props.dataLS?.phone,
            birthdate: props.dataLS?.birthdate,
            card_id: props.dataLS?.card_id,
            tax_id: props.dataLS?.tax_id,
            card_option: props.dataLS?.card_option,
            pass_type: props.dataLS?.pass_type,
        }})

    const minDate = DateTime.fromISO('1900-01-01T00:00:00')
    const maxDate = DateTime.now()

    const card_option = watch("card_option")
    const pass_type = watch("pass_type")
    const name = watch("name")
    const address = watch("address")
    const postal_code = watch("postal_code")
    const city = watch("city")
    const email = watch("email")
    const phone = watch("phone")
    const birthdate = watch("birthdate")
    const card_id = watch("card_id")
    const checked_terms = watch("checked_terms")
    const checked_permissions = watch("checked_permissions")
    const checked_sales = watch("checked_sales")

    const cheapPasses = [
        //'estudante',
        'estudante sub 23 a',
        'estudante sub 23 b',
        'estudante sub 23',
        'estudante sub 18',
        'estudante 4_18 a',
        'estudante 4_18 b',
        'passe sub18+tp',
        'passe sub23+tp'
    ]
    const travelCardID_250 = props?.types ? Object.keys(props?.types).find(key => props?.types[key]?.physical.price === 250) : null
    const travelCardID_500 = props.types ? Object.keys(props?.types).find(key => props?.types[key]?.physical.price === 500) : null

    const fileInputRef = useRef(null)
    const [photoFile, setPhotoFile] = useState(null)
    const [profilePhoto, setProfilePhoto] = useState(null)
    const [isVisible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingUpload, setLoadingUpload] = useState(false)
    const [formStep, setStep] = useState(props.errorID ? 3 : 1)
    const [data, setData] = useState(props.errorID ? props.dataLS : null)
    const [passTypes, setPassTypes] = useState(null)
    const [cardOptions, setCardOptions] = useState(null)
    const [profileID, setProfileID] = useState(null)
    const [travelCardID, setTravelCardID] = useState(null)
    const [requiredDocs, setRequiredDocs] = useState(null)
    const [isScrolledToBottom, setIsScrolledToBottom] = useState(false)
    const [repeatedCardID, setRepeatedCardID] = useState(false)
    const [repeatedTaxID, setRepeatedTaxID] = useState(false)
    const [invalidEmail, setInvalidEmail] = useState(false)

    const getSelectedProfileID = () => {
        const res = props?.prices.find((ticket) => ticket.variationName ? ticket.variationName === pass_type : ticket.compoundName === pass_type)
        return res.profileID
    }

    const getSelectedRequiredDocuments = () => {
        const res = props?.prices.find((ticket) => ticket.variationName ? ticket.variationName === pass_type : ticket.compoundName === pass_type)
        setRequiredDocs(res.requiredDocuments || [])
    }

    useEffect(() => {
        if (props?.prices && formStep === 2) {
            const today = DateTime.now()
            const age = Math.floor(today.diff(DateTime.fromISO(data.birthdate), 'years').years)

            const passes = props.prices.filter(ticket => !(ticket.minAge !== null && age < ticket.minAge) &&
              !(ticket.maxAge !== null && age > ticket.maxAge))
              .map(ticket => ({
                  labelComponent: (
                    <Text fontSize="14px" fontWeight="600" color="#424D5F">
                        {ticket?.variationName ? ticket.variationName : ticket.compoundName}
                    </Text>
                  ),
                  value: ticket?.variationName ? ticket.variationName : ticket.compoundName
              }))

            setPassTypes(passes)
        }
    },[formStep])

    useEffect(() => {
        if (pass_type) {
            const typesKeys = Object.keys(props.types)
            const selectedType = typesKeys.length > 1 ? cheapPasses.includes(pass_type.toLowerCase()) ? travelCardID_250 : travelCardID_500 : typesKeys[0]
            const digitalPrice = props.types[selectedType].digital.price / 100
            const formattedDigitalPrice = digitalPrice.toLocaleString(undefined, { minimumFractionDigits: 2 })
            const physicalPrice = props.types[selectedType].physical.price / 100
            const formattedPhysicalPrice = physicalPrice.toLocaleString(undefined, { minimumFractionDigits: 2 })
            customLog([typesKeys, cheapPasses, pass_type, selectedType, physicalPrice])

            const card = []

            if (props.types[selectedType].digital.sale) {
                card.push({
                    labelComponent: (
                      <Flex align="center" justify="space-between" w="100%">
                          <Flex gap="4px" align="center">
                              <Text fontSize="14px" fontWeight="600" color="#424D5F">
                                  {t("ticket_fares.digital_card")}
                              </Text>
                              <Text fontSize="16px" fontWeight="700" color="#7537FA">
                                  {t("ticket_fares.on_pick_hub_app")}
                              </Text>
                          </Flex>
                          <Text fontSize="14px" fontWeight="700" color="#424D5F">
                              €{formattedDigitalPrice}
                          </Text>
                      </Flex>
                    ),
                    value: "digital_card"
                });
            }
            if (props.types[selectedType].physical.sale) {
                card.push({
                    labelComponent: (
                      <Flex align="center" justify="space-between" w="100%">
                          <Text fontSize="14px" fontWeight="600" color="#424D5F">
                              {t("ticket_fares.physical_card")}
                          </Text>
                          <Text fontSize="14px" fontWeight="700" color="#424D5F">
                              €{formattedPhysicalPrice}
                          </Text>
                      </Flex>
                    ),
                    value: "physical_card"
                });
            }

            setCardOptions(card)
            setTravelCardID(cheapPasses.includes(pass_type.toLowerCase()) ? travelCardID_250 : travelCardID_500)
        }
    },[pass_type])


    useEffect(() => {
        if (window.location.pathname.includes('/personal-info')) {
            props.onOpen()
            setStep(1)
        }
        else if (window.location.pathname.includes('/pass-info')) {
            if (!name && !email) {
                props.onOpen()
                setStep(1)
            }
            else {
                setStep(2)
            }
        }
        else if (window.location.pathname.includes('/checkout')) {
            if (!name && !email && !card_option && !pass_type) {
                props.onOpen()
                setStep(1)
            }
            else {
                setStep(3)
            }
        }
    },[window.location.pathname])

    useEffect(() => {
        if (formStep === 1 && props.isOpen) {
            navigate(`${props.path}/pass-request/personal-info`)
        }
        if (props.errorID) {
            reset({
                name: props.dataLS?.name,
                address: props.dataLS?.address,
                postal_code: props.dataLS?.postal_code,
                city: props.dataLS?.city,
                email: props.dataLS?.email,
                phone: props.dataLS?.phone,
                birthdate: DateTime.fromISO(props.dataLS?.birthdate),
                card_id: props.dataLS?.card_id,
                tax_id: props.dataLS?.tax_id,
            })
        }
        if (props.isOpen) {
            setStep(props.errorID ? 3 : 1)
            navigate(props.errorID ? `${props.path}/pass-request/checkout` : `${props.path}/pass-request/personal-info`)
            setIsScrolledToBottom(false)
            setLoading(false)
            setTimeout(() => {
                setVisible(true)
            }, 500)
        }
        if (!props.isOpen) {
            reset({
                name: null,
                address: null,
                postal_code: null,
                city: null,
                email: null,
                phone: null,
                birthdate: null,
                card_id: null,
                tax_id: null,
                card_option: null,
                pass_type: null
            })
            setIsScrolledToBottom(false)
            setStep(props.errorID ? 3 : 1)
            setRepeatedCardID(false)
            setRepeatedTaxID(false)
            setInvalidEmail(false)
            setData(null)
            setLoading(false)
            setProfilePhoto(null)
            setVisible(false)
        }
    },[props.isOpen, props.files])

    useEffect(() => {
        window.scrollTo(0, 0)
    },[formStep])

    useEffect(() => {
        if (pass_type) {
            const profile = getSelectedProfileID(pass_type)
            setProfileID(profile)
            getSelectedRequiredDocuments(pass_type)
        }
    },[pass_type])

    const handleProfilePhotoUpload = () => {
        clearErrors()
        setLoadingUpload(true)
        fileInputRef.current.click()
        setLoadingUpload(false)
    }

    const handleSelectedProfilePhoto = (event) => {
        clearErrors()
        setLoadingUpload(true)
        const file = event.target.files[0]
        if (file && (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg')) {
            setPhotoFile(file)
            setProfilePhoto(URL.createObjectURL(file))
        }
        else {
            fileInputRef.current.value = null
            setError('profile_photo', { message: t("ticket_fares.invalid_format_error") })
            setPhotoFile(null)
            setProfilePhoto(null)
        }
        setLoadingUpload(false)
    }

    const handleClearPhoto = () => {
        setPhotoFile(null)
        setProfilePhoto(null)
        fileInputRef.current.value = null
    }

    const onSubmitStep1 = async (values) => {
        setStep(2)
        navigate(`${props.path}/pass-request/pass-info`)
        try {
            setData({
                name: values.name,
                address: values.address,
                postal_code: values.postal_code,
                city: values.city,
                email: values.email,
                phone: values.phone,
                birthdate: values.birthdate.toFormat('yyyy-MM-dd'),
                card_id: values.card_id,
                tax_id: values?.tax_id
            })
            setIsScrolledToBottom(false)
        } catch (error) {
            customError(error)
        }
    }

    const onSubmitStep2 = async (values) => {
        setStep(3)
        navigate(`${props.path}/pass-request/checkout`)
        const typesKeys = Object.keys(props.types)
        const selectedType = typesKeys.length > 1 ? cheapPasses.includes(pass_type.toLowerCase()) ? travelCardID_250 : travelCardID_500 : typesKeys[0]
        const digitalPrice = props.types[selectedType].digital.price
        const physicalPrice = props.types[selectedType].physical.price
        try {
            const docFiles = []
            requiredDocs.forEach(doc => docFiles.push(values[doc]))
            setData({
                ...data,
                cardOption: card_option,
                passType: pass_type,
                profileID: profileID,
                travelCardID: travelCardID,
                total: card_option === "physical_card" ? physicalPrice : digitalPrice,
                photo: photoFile,
                files: docFiles,
                filesSize: docFiles.length
            })
            setIsScrolledToBottom(false)
        } catch (error) {
            customError(error)
        }
    }

    const handlePay = async () => {
        setLoading(true)
        setRepeatedCardID(false)
        setRepeatedTaxID(false)
        setInvalidEmail(false)
        try {
            await storeFileInDB(data.photo, 'photo')
            for (let i = 0; i < data.files.length; i++) {
                await storeFileInDB(data.files[i], 'file_' + i)
            }
        }
        catch (error) {
            customError(error)
        }
        try {
            const resTicketing = await validateCustomer(data.cardOption, data.card_id, data.tax_id, data.email)
            if (resTicketing.message === 'Success') {
                data.ticketing = resTicketing.data
                cookies.set('PassRequestData', JSON.stringify(data), { maxAge: 60 * 60, secure: true, sameSite: 'strict' })
                const cookieData = cookies.get('PassRequestData')
                if (cookieData) {
                    if (data.total === 0) {
                        navigate('/ticket-fares/success')
                        props.onClose()
                        setLoading(false)
                    }
                    else {
                        try {
                            const res = await getPaymentInfo(data)
                            setLoading(false)
                            props.setOrderCode(res.data.orderCode)
                            props.onOpenRedirect()
                        }
                        catch (error) {
                            customError(error)
                            setLoading(false)
                        }
                    }
                }
                else {
                    setLoading(false)
                    onOpen()
                }
            }
            else {
                if (resTicketing?.message === 'user_exists') {
                    setStep(1)
                    navigate(`${props.path}/pass-request/personal-info`)
                    if (resTicketing?.data?.repeatedFields.includes('idDocument')) {
                        setError('card_id', { message: t('ticket_fares.data_already_used_hint') })
                        setRepeatedCardID(true)
                    }
                    if (resTicketing?.data?.repeatedFields.includes('taxNumber')) {
                        setError('tax_id', { message: t('ticket_fares.data_already_used_hint') })
                        setRepeatedTaxID(true)
                    }
                }
                if (resTicketing?.message === 'pick_account_not_found') {
                    setStep(1)
                    navigate(`${props.path}/pass-request/personal-info`)
                    setError('email', {message: t('ticket_fares.digital_pass_pick_hub_email_inline')})
                    setInvalidEmail(true)
                }
                setLoading(false)
            }
        }
        catch (error) {
            customError(error)
            setLoading(false)
        }
    }

    const handleScroll = (event) => {
        const element = event.target
        if (element.scrollHeight - element.scrollTop === element.clientHeight) {
            setIsScrolledToBottom(true)
        } else {
            setIsScrolledToBottom(false)
        }
    }

    function handleStepBack() {
        if (formStep === 2) {
            navigate(`${props.path}/pass-request/personal-info`)
        }
        else if (formStep === 3) {
            navigate(`${props.path}/pass-request/pass-info`)
        }
        setStep(formStep - 1)
    }

    function handleStepNext(number) {
        if(number === 1) {
            navigate(`${props.path}/pass-request/personal-info`)
        }
        if (number === 2) {
            navigate(`${props.path}/pass-request/pass-info`)
        }
        else if (number === 3) {
            navigate(`${props.path}/pass-request/checkout`)
        }
        setStep(number)
    }

    function handleCloseForm() {
        props.onOpenCancel()
        clearErrors()
    }

    return (
      <Modal
        isOpen={props.isOpen}
        onClose={props.onClose}
        onOpen={props.onOpen}
        trapFocus={false}
        closeOnOverlayClick={false}
        autoFocus={false}
        initialFocusRef={initialRef}
        size={{sm: "full", lg: ""}}
      >
          <ModalOverlay />
          <ModalContent
            borderRadius={{lg: "8px"}}
            border={{lg: "1px solid " + config.mainColor}}
            maxW={{lg: "702px"}}
            maxH={ {lg: "calc(100vh - 64px)"}}
            mt={{lg: "32px"}}
            mb={{lg: "32px"}}
          >
              <Flex direction="column" w="100%" h="100%" mt={{sm: "67px", md: "67px", lg: "0px", xl: "0px"}}>
                  { formStep === 1 &&
                    <Flex
                      direction="column"
                      as="form"
                      onSubmit={handleSubmit(onSubmitStep1)}
                    >
                        <Flex
                          position={{sm: "fixed", lg: "inherit"}}
                          padding={{sm: "16px 24px", lg: "16px 32px"}}
                          zIndex="5"
                          bg="white"
                          gap="16px"
                          direction="column"
                          w="100%"
                          boxShadow="0px 2px 20px 1px rgba(0, 0, 0, 0.1)"
                          borderRadius={{lg: "8px 8px 0 0"}}
                        >
                            <Flex align="flex-start" w="100%">
                                <Flex justify="center" align="center" w="100%">
                                    <Text fontWeight="700" fontSize={{sm: "24px", lg: "20px"}} color="#001D20">
                                        {t("ticket_fares.order_pass")}
                                    </Text>
                                </Flex>
                                <Flex justify="flex-end" align="flex-end">
                                    <Flex as="button" onClick={handleCloseForm}>
                                        <Image src={closeButton} />
                                    </Flex>
                                </Flex>
                            </Flex>

                            <Flex borderRadius={{lg: "8px"}} gap="16px" justify="center" align="center">
                                <Flex
                                  direction={{sm: "column", lg: "row"}}
                                  gap="16px"
                                  align="center"
                                  justify="flex-start"
                                >
                                    <Flex
                                      justify="center"
                                      align="center"
                                      padding="8px 12px"
                                      w="32px"
                                      h="32px"
                                      border={formStep !== 1 && (name && email && address && postal_code && city && phone && birthdate && card_id) ? "1px solid #98A2B3" : null}
                                      bg={formStep === 1 ? config.mainColor : (name && email && address && postal_code && city && phone && birthdate && card_id) ? "white" : "#CACFD8"}
                                      borderRadius="100px"
                                    >
                                        <Text fontSize="20px" fontWeight="600" color={formStep === 1 ? "white" : "#667085"}>1</Text>
                                    </Flex>
                                    <Text fontSize="14px" fontWeight={formStep === 1 ? "700" : "400"} color="#282828" textAlign={{sm: "center", md: "center", lg: "left", xl: "left"}}>{t("ticket_fares.enter_information")}</Text>
                                </Flex>
                                <Divider display={{sm: "none", lg: "flex"}} maxW="35px" border="1px solid rgba(40, 40, 40, 0.25)" />
                                <Flex
                                  direction={{sm: "column", lg: "row"}}
                                  gap="16px"
                                  align="center"
                                  justify="center"
                                  as="button"
                                  onClick={() => handleSubmit(onSubmitStep1)}
                                >
                                    <Flex
                                      justify="center"
                                      align="center"
                                      padding="8px 12px"
                                      w="32px"
                                      h="32px"
                                      border={formStep !== 2 && (profilePhoto && card_option && pass_type && checked_terms && checked_permissions) ? "1px solid #98A2B3" : null}
                                      bg={formStep === 2 ? config.mainColor : (profilePhoto && card_option && pass_type && checked_terms && checked_permissions) ? "white" : "#CACFD8"}
                                      borderRadius="20px"
                                    >
                                        <Text fontSize="20px" fontWeight="600" color={formStep === 2 ? "white" : "#667085"}>2</Text>
                                    </Flex>
                                    <Text fontSize="14px" fontWeight={formStep === 2 ? "700" : "400"} color="#282828" textAlign={{sm: "center", md: "center", lg: "left", xl: "left"}}>{t("ticket_fares.select_pass_type")}</Text>
                                </Flex>
                                <Divider display={{sm: "none", lg: "flex"}} maxW="35px" border="1px solid rgba(40, 40, 40, 0.25)" />
                                <Flex
                                  direction={{sm: "column", lg: "row"}}
                                  gap="16px"
                                  align="center"
                                  justify="center"
                                  as="button"
                                  onClick={profilePhoto && card_option && pass_type && checked_terms && checked_permissions ? () => handleStepNext(3) : () => handleSubmit(onSubmitStep1)}
                                >
                                    <Flex
                                      justify="center"
                                      align="center"
                                      padding="8px 12px"
                                      w="32px"
                                      h="32px"
                                      border={formStep !== 3 && checked_sales ? "1px solid #98A2B3" : null}
                                      bg={formStep === 3 ? config.mainColor : checked_sales ? "white" : "#CACFD8"}
                                      borderRadius="20px"
                                    >
                                        <Text fontSize="20px" fontWeight="600" color={formStep === 3 ? "white" : "#667085"}>3</Text>
                                    </Flex>
                                    <Text fontSize="14px" fontWeight={formStep === 3 ? "700" : "400"} color="#282828" textAlign={{sm: "center", md: "center", lg: "left", xl: "left"}}>{t("ticket_fares.purchase_travel_card")}</Text>
                                </Flex>
                            </Flex>
                        </Flex>

                        { props.errorID && errorIDs.includes(props.errorID) &&
                          <TransactionErrorBanner errorID={props.errorID} isVisible={isVisible} />
                        }

                        { invalidEmail &&
                          <Flex
                            display={{sm: "none", lg: "flex"}}
                            direction="column"
                            gap="8px"
                            padding="16px"
                            align="flex-start"
                            justify="center"
                            bg="#FEECAA"
                            boxShadow="0px 4px 10px 1px rgba(0, 0, 0, 0.1)"
                            w="100%"
                            zIndex="50"
                          >
                              <Text fontSize="14px" fontWeight="700" color="#91600E">{t("ticket_fares.digital_pass_pick_hub_email_not_found_title")}</Text>
                              <Text fontSize="13px" color="#1D2939">{t("ticket_fares.digital_pass_pick_hub_email_not_found_subtitle")}</Text>
                          </Flex>
                        }
                        { repeatedCardID && repeatedTaxID &&
                          <Flex
                            display={{sm: "none", lg: "flex"}}
                            direction="column"
                            gap="8px"
                            padding="16px"
                            align="flex-start"
                            justify="center"
                            bg="#FEECAA"
                            boxShadow="0px 4px 10px 1px rgba(0, 0, 0, 0.1)"
                            w="100%"
                            zIndex="50"
                          >
                              <Text fontSize="14px" fontWeight="700" color="#91600E">{t("ticket_fares.id_nif_already_associated_title")}</Text>
                              <Text fontSize="13px" color="#1D2939">{t("ticket_fares.id_already_associated_subtitle")}</Text>
                          </Flex>
                        }
                        { repeatedCardID && !repeatedTaxID &&
                          <Flex
                            display={{sm: "none", lg: "flex"}}
                            direction="column"
                            gap="8px"
                            padding="16px"
                            align="flex-start"
                            justify="center"
                            bg="#FEECAA"
                            boxShadow="0px 4px 10px 1px rgba(0, 0, 0, 0.1)"
                            w="100%"
                            zIndex="50"
                          >
                              <Text fontSize="14px" fontWeight="700" color="#91600E">{t("ticket_fares.id_already_associated_title")}</Text>
                              <Text fontSize="13px" color="#1D2939">{t("ticket_fares.id_already_associated_subtitle")}</Text>
                          </Flex>
                        }
                        { !repeatedCardID && repeatedTaxID &&
                          <Flex
                            display={{sm: "none", lg: "flex"}}
                            direction="column"
                            gap="8px"
                            padding="16px"
                            align="flex-start"
                            justify="center"
                            bg="#FEECAA"
                            boxShadow="0px 4px 10px 1px rgba(0, 0, 0, 0.1)"
                            w="100%"
                            zIndex="50"
                          >
                              <Text fontSize="14px" fontWeight="700" color="#91600E">{t("ticket_fares.nif_already_associated_title")}</Text>
                              <Text fontSize="13px" color="#1D2939">{t("ticket_fares.id_already_associated_subtitle")}</Text>
                          </Flex>
                        }

                        <Flex
                          direction="column"
                          bg="#F9F9FB"
                          borderRadius={{lg: "0 0 8px 8px"}}
                          w="100%"
                          h="100%"
                          pt={{sm: "170px", lg: "0"}}
                          pr="8px"
                        >
                            { invalidEmail &&
                              <Flex
                                display={{sm: "flex", lg: "none"}}
                                direction="column"
                                gap="8px"
                                padding="16px"
                                align="flex-start"
                                justify="center"
                                bg="#FEECAA"
                                boxShadow="0px 4px 10px 1px rgba(0, 0, 0, 0.1)"
                                w="100%"
                                zIndex="50"
                                position="fixed"
                              >
                                  <Text fontSize="14px" fontWeight="700" color="#91600E">{t("ticket_fares.digital_pass_pick_hub_email_not_found_title")}</Text>
                                  <Text fontSize="13px" color="#1D2939">{t("ticket_fares.digital_pass_pick_hub_email_not_found_subtitle")}</Text>
                              </Flex>
                            }
                            { repeatedCardID && repeatedTaxID &&
                              <Flex
                                display={{sm: "flex", lg: "none"}}
                                direction="column"
                                gap="8px"
                                padding="16px"
                                align="flex-start"
                                justify="center"
                                bg="#FEECAA"
                                boxShadow="0px 4px 10px 1px rgba(0, 0, 0, 0.1)"
                                w="100%"
                                zIndex="50"
                                position="fixed"
                              >
                                  <Text fontSize="14px" fontWeight="700" color="#91600E">{t("ticket_fares.id_nif_already_associated_title")}</Text>
                                  <Text fontSize="13px" color="#1D2939">{t("ticket_fares.id_already_associated_subtitle")}</Text>
                              </Flex>
                            }
                            { repeatedCardID && !repeatedTaxID &&
                              <Flex
                                display={{sm: "flex", lg: "none"}}
                                direction="column"
                                gap="8px"
                                padding="16px"
                                align="flex-start"
                                justify="center"
                                bg="#FEECAA"
                                boxShadow="0px 4px 10px 1px rgba(0, 0, 0, 0.1)"
                                w="100%"
                                zIndex="50"
                                position="fixed"
                              >
                                  <Text fontSize="14px" fontWeight="700" color="#91600E">{t("ticket_fares.id_already_associated_title")}</Text>
                                  <Text fontSize="13px" color="#1D2939">{t("ticket_fares.id_already_associated_subtitle")}</Text>
                              </Flex>
                            }
                            { !repeatedCardID && repeatedTaxID &&
                              <Flex
                                display={{sm: "flex", lg: "none"}}
                                direction="column"
                                gap="8px"
                                padding="16px"
                                align="flex-start"
                                justify="center"
                                bg="#FEECAA"
                                boxShadow="0px 4px 10px 1px rgba(0, 0, 0, 0.1)"
                                w="100%"
                                zIndex="50"
                                position="fixed"
                              >
                                  <Text fontSize="14px" fontWeight="700" color="#91600E">{t("ticket_fares.nif_already_associated_title")}</Text>
                                  <Text fontSize="13px" color="#1D2939">{t("ticket_fares.id_already_associated_subtitle")}</Text>
                              </Flex>
                            }
                            <Flex
                              direction="column"
                              gap="24px"
                              align="flex-start"
                              mt={repeatedCardID || repeatedTaxID || invalidEmail || props.errorID ? {sm: "90px", lg: "0"} : undefined}
                              padding={{sm: "32px 16px 32px 24px", lg: "24px 32px"}}
                              h={repeatedCardID || repeatedTaxID || invalidEmail || props.errorID ? {lg: "calc(100vh - 354px)"} : {lg: "calc(100vh - 254px)"}}
                              w="100%"
                              overflowY={{lg: "scroll"}}
                              css={{
                                  "&::-webkit-scrollbar": {
                                      width: "8px", // Set the width of the scrollbar
                                  },
                                  "&::-webkit-scrollbar-thumb": {
                                      borderRadius: "8px",
                                      backgroundColor: "#98A2B3", // Set the color of the scrollbar thumb
                                  },
                                  "&::-webkit-scrollbar-track": {
                                      borderRadius: "8px",
                                      backgroundColor: "none", // Set the color of the scrollbar track
                                  },
                              }}
                              onScroll={handleScroll}
                            >
                                { !invalidEmail && !repeatedTaxID && !repeatedCardID &&
                                  <Flex
                                    gap="16px"
                                    padding="8px 16px"
                                    bg="#E0EAFF"
                                    border="1px solid #C7D7FE"
                                    rounded="4px"
                                    align="center"
                                  >
                                      <Image w={{sm: "64px", lg: "32px"}} src={infoIconBlue} />
                                      <Text fontSize="14px" color="#444CE7">
                                          <Trans i18nKey="ticket_fares.digital_pass_pick_hub_account_created">
                                              If you choose a digital pass, you must already have a Pick Hub account created. Download Pick Hub app <Link className="chakra-custom-link" sx={{fontWeight: "600", textDecoration: "underline"}} href="https://pickbyubirider.page.link/downloadpick" target="_blank"><Text textDecoration='underline' fontWeight='600'>here</Text></Link>.
                                          </Trans>
                                      </Text>
                                  </Flex>
                                }

                                <Controller
                                  name='name'
                                  control={control}
                                  rules={{ required: t("authentication.required_field") }}
                                  render={({ field }) => (
                                    <FormItem required label={t("common.name")} error={errors.name}>
                                        <TextInput
                                          {...field}
                                          background={"white"}
                                        />
                                    </FormItem>
                                  )}
                                />

                                <Controller
                                  name='address'
                                  control={control}
                                  rules={{ required: t("authentication.required_field") }}
                                  render={({ field }) => (
                                    <FormItem required label={t("common.address")} error={errors.address}>
                                        <TextInput
                                          {...field}
                                          background={"white"}
                                        />
                                    </FormItem>
                                  )}
                                />

                                <Flex direction={{sm: "column", md: "column", lg: "row", xl: "row"}} gap="24px" width="100%">
                                    <Controller
                                      name='postal_code'
                                      control={control}
                                      rules={{
                                          required: t("authentication.required_field"),
                                          pattern: {
                                              value: /^\d{4}-\d{3}$/,
                                              message: t("authentication.invalid_postal_code"),
                                          },
                                      }}
                                      render={({ field}) => (
                                        <FormItem required label={t("common.postal_code")} error={errors.postal_code}>
                                            <TextInput
                                              background={"white"}
                                              placeholder={t("authentication.enter_postal_code")}
                                              {...field}
                                              onChange={(e) => {
                                                  const value = e
                                                  if (value === null) {
                                                      field.onChange(null)
                                                  }
                                                  else if (isNaN(parseInt(value.charAt(value.length - 1))) && value.charAt(value.length - 1) !== '-') {
                                                      field.onChange(field.value)
                                                  }
                                                  else if (field.value === null) {
                                                      field.onChange(value)
                                                  }
                                                  else if (value?.length <= 8 || !value) {
                                                      if (value?.length < field.value.length && field.value.charAt(field.value.length - 1) === '-') {
                                                          field.onChange(value.slice(0, -1))
                                                      } else if (value?.length === 4 && value?.indexOf('-') === -1) {
                                                          field.onChange(value + '-')
                                                      } else {
                                                          field.onChange(value)
                                                      }
                                                  }
                                              }}
                                            />
                                        </FormItem>
                                      )}
                                    />

                                    <Controller
                                      name='city'
                                      control={control}
                                      rules={{ required: t("authentication.required_field") }}
                                      render={({ field }) => (
                                        <FormItem required label={t("common.city")} error={errors.city}>
                                            <TextInput
                                              background={"white"}
                                              {...field}
                                            />
                                        </FormItem>
                                      )}
                                    />
                                </Flex>

                                <Controller
                                  name='email'
                                  control={control}
                                  rules={{ required: t("authentication.required_field"),
                                      validate: emailValidation
                                  }}
                                  render={({ field }) => (
                                    <FormItem required label={t("common.email")} error={errors.email} hint={t("ticket_fares.digital_pass_pick_hub_email_inline")}>
                                        <TextInput
                                          background={"white"}
                                          {...field}
                                        />
                                    </FormItem>
                                  )}
                                />

                                <Flex direction={{sm: "column", md: "column", lg: "row", xl: "row"}} gap="24px" width="100%">
                                    <Controller
                                      name='phone'
                                      control={control}
                                      rules={{
                                          required: t("authentication.required_field"),
                                          validate: { phone_number: v => (v !== null ? validatePhoneNumber(v) : true) || t("authentication.invalid_phone") },
                                      }}
                                      render={({ field }) => (
                                        <FormItem required label={t("common.phone")} error={errors.phone}>
                                            <PhoneNumberInput
                                              {...field}
                                              background={"white"}
                                            />
                                        </FormItem>
                                      )}
                                    />

                                    <Controller
                                      name='birthdate'
                                      control={control}
                                      rules={{ required: t("authentication.required_field") }}
                                      render={({ field }) => (
                                        <FormItem required label={t("common.birthdate")} error={errors.birthdate}>
                                            <DateInput
                                              {...field}
                                              background={"white"}
                                              minDate={minDate}
                                              maxDate={maxDate}
                                            />
                                        </FormItem>
                                      )}
                                    />
                                </Flex>

                                <Flex direction={{sm: "column", md: "column", lg: "row", xl: "row"}} gap="24px" width="100%">
                                    <Controller
                                      name='card_id'
                                      control={control}
                                      rules={{ required: t("authentication.required_field") }}
                                      render={({ field }) => (
                                        <FormItem required label={t("common.card_id")} error={errors.card_id}>
                                            <TextInput
                                              css={{backgroundColor: "white"}}
                                              {...field}
                                            />
                                        </FormItem>
                                      )}
                                    />

                                    <Controller
                                      name='tax_id'
                                      control={control}
                                      rules={{
                                          required: t("authentication.required_field"),
                                          validate: nifValidation
                                      }}
                                      render={({ field }) => (
                                        <FormItem required label={t("common.taxpayer_id")} error={errors.tax_id}>
                                            <TextInput
                                              css={{backgroundColor: "white"}}
                                              {...field}
                                              onChange={(e) => {
                                                  const value = e
                                                  if (value === null) {
                                                      field.onChange(null)
                                                  }
                                                  else if (isNaN(parseInt(value.charAt(value.length - 1)))) {
                                                      field.onChange(field.value)
                                                  }
                                                  else if (field.value === null || value?.length <= 9 || !value) {
                                                      field.onChange(value)
                                                  }
                                              }}
                                            />
                                        </FormItem>
                                      )}
                                    />
                                </Flex>
                            </Flex>
                            <Flex
                              position="relative"
                              align="flex-end"
                              justify="flex-end"
                              mr="-8px"
                              padding={{sm: "24px", lg: "16px 32px"}}
                              bg={isScrolledToBottom ? "#F9F9FB" : "white"}
                              boxShadow={isScrolledToBottom ? "none" : "0px 2px 20px 1px rgba(0, 0, 0, 0.1)"}
                              borderRadius={{lg: "0 0 8px 8px"}}
                              transition="background-color 0.3s ease, box-shadow 0.3s ease"
                            >
                                <Button
                                  submit
                                  ref={initialRef}
                                  style="solid"
                                  type="primary"
                                  label={t("common.next")}
                                  css={{width: "160px"}}
                                />
                            </Flex>
                        </Flex>
                    </Flex>
                  }
                  { formStep === 2 &&
                    <Flex
                      direction="column"
                      as="form"
                      onSubmit={handleSubmit(onSubmitStep2)}
                    >
                        <Flex
                          position={{sm: "fixed", lg: "inherit"}}
                          padding={{sm: "16px 24px", lg: "16px 32px"}}
                          zIndex="5"
                          bg="white"
                          gap="16px"
                          direction="column"
                          w="100%"
                          boxShadow="0px 2px 20px 1px rgba(0, 0, 0, 0.1)"
                          borderRadius={{lg: "8px 8px 0 0"}}
                        >
                            <Flex align="flex-start" w="100%">
                                <Flex justify="center" align="center" w="100%">
                                    <Text fontWeight="700" fontSize={{sm: "24px", lg: "20px"}} color="#001D20">
                                        {t("ticket_fares.order_pass")}
                                    </Text>
                                </Flex>
                                <Flex justify="flex-end" align="flex-end">
                                    <Flex as="button" onClick={handleCloseForm}>
                                        <Image src={closeButton} />
                                    </Flex>
                                </Flex>
                            </Flex>

                            <Flex borderRadius={{lg: "8px"}} gap="16px" justify="center" align="center">
                                <Flex
                                  direction={{sm: "column", lg: "row"}}
                                  gap="16px"
                                  align="center"
                                  justify="flex-start"
                                  as="button"
                                  onClick={() => handleStepNext(1)}
                                >
                                    <Flex
                                      justify="center"
                                      align="center"
                                      padding="8px 12px"
                                      w="32px"
                                      h="32px"
                                      border={formStep !== 1 && (name && email && address && postal_code && city && phone && birthdate && card_id) ? "1px solid #98A2B3" : null}
                                      bg={formStep === 1 ? config.mainColor : (name && email && address && postal_code && city && phone && birthdate && card_id) ? "white" : "#CACFD8"}
                                      borderRadius="100px"
                                    >
                                        <Text fontSize="20px" fontWeight="600" color={formStep === 1 ? "white" : "#667085"}>1</Text>
                                    </Flex>
                                    <Text fontSize="14px" fontWeight={formStep === 1 ? "700" : "400"} color="#282828" textAlign={{sm: "center", md: "center", lg: "left", xl: "left"}}>{t("ticket_fares.enter_information")}</Text>
                                </Flex>
                                <Divider display={{sm: "none", lg: "flex"}} maxW="35px" border="1px solid rgba(40, 40, 40, 0.25)" />
                                <Flex
                                  direction={{sm: "column", lg: "row"}}
                                  gap="16px"
                                  align="center"
                                  justify="center"
                                >
                                    <Flex
                                      justify="center"
                                      align="center"
                                      padding="8px 12px"
                                      w="32px"
                                      h="32px"
                                      border={formStep !== 2 && (profilePhoto && card_option && pass_type && checked_terms && checked_permissions) ? "1px solid #98A2B3" : null}
                                      bg={formStep === 2 ? config.mainColor : (profilePhoto && card_option && pass_type && checked_terms && checked_permissions) ? "white" : "#CACFD8"}
                                      borderRadius="100px"
                                    >
                                        <Text fontSize="20px" fontWeight="600" color={formStep === 2 ? "white" : "#667085"}>2</Text>
                                    </Flex>
                                    <Text fontSize="14px" fontWeight={formStep === 2 ? "700" : "400"} color="#282828" textAlign={{sm: "center", md: "center", lg: "left", xl: "left"}}>{t("ticket_fares.select_pass_type")}</Text>
                                </Flex>
                                <Divider display={{sm: "none", lg: "flex"}} maxW="35px" border="1px solid rgba(40, 40, 40, 0.25)" />
                                <Flex
                                  direction={{sm: "column", lg: "row"}}
                                  gap="16px"
                                  align="center"
                                  justify="center"
                                  as="button"
                                  onClick={() => handleSubmit(onSubmitStep2)}
                                >
                                    <Flex
                                      justify="center"
                                      align="center"
                                      padding="8px 12px"
                                      w="32px"
                                      h="32px"
                                      border={formStep !== 3 && checked_sales ? "1px solid #98A2B3" : null}
                                      bg={formStep === 3 ? config.mainColor : checked_sales ? "white" : "#CACFD8"}
                                      borderRadius="100px"
                                    >
                                        <Text fontSize="20px" fontWeight="600" color={formStep === 3 ? "white" : "#667085"}>3</Text>
                                    </Flex>
                                    <Text fontSize="14px" fontWeight={formStep === 3 ? "700" : "400"} color="#282828" textAlign={{sm: "center", md: "center", lg: "left", xl: "left"}}>{t("ticket_fares.purchase_travel_card")}</Text>
                                </Flex>
                            </Flex>
                        </Flex>

                        { props.errorID && errorIDs.includes(props.errorID) &&
                          <TransactionErrorBanner errorID={props.errorID} isVisible={isVisible} />
                        }

                        <Flex
                          w="100%"
                          direction="column"
                          bg="#F9F9FB"
                          pr="8px"
                          borderRadius={{lg: "0 0 8px 8px"}}
                          flex="1"
                          pt={{sm: "170px", lg: "0"}}
                        >
                            <Flex
                              direction="column"
                              gap="24px"
                              align="flex-start"
                              padding={{sm: "32px 16px 32px 24px", lg: "24px 32px"}}
                              h={props.errorID ? {lg: "calc(100vh - 354px)"} : {lg: "calc(100vh - 254px)"}}
                              w="100%"
                              overflowY={{lg: "scroll"}}
                              css={{
                                  "&::-webkit-scrollbar": {
                                      width: "8px", // Set the width of the scrollbar
                                  },
                                  "&::-webkit-scrollbar-thumb": {
                                      borderRadius: "8px",
                                      backgroundColor: "#98A2B3", // Set the color of the scrollbar thumb
                                  },
                                  "&::-webkit-scrollbar-track": {
                                      borderRadius: "8px",
                                      backgroundColor: "none", // Set the color of the scrollbar track
                                  },
                              }}
                              onScroll={handleScroll}
                            >
                                <Flex direction="column" gap="24px" align={{sm: "center", lg: "flex-start"}} w="100%">
                                    <Flex gap="8px" align="center" w="100%">
                                        <Controller
                                          name='profile_photo'
                                          control={control}
                                          rules={{ required: profilePhoto ? null : t("authentication.required_field") }}
                                          render={() => (
                                            <FormItem required label={t("common.profile_photo")} error={errors.profile_photo}>
                                                <Flex direction={{sm: "column", lg: "row"}} mt="16px" gap="32px" align="center" justify={{sm: "center", lg: "flex-start"}} w="100%">
                                                    <input
                                                      type="file"
                                                      accept="image/jpg, image/jpeg, image/png"
                                                      ref={fileInputRef}
                                                      style={{ display: 'none' }}
                                                      onChange={handleSelectedProfilePhoto}
                                                    />
                                                    <Flex cursor="pointer" onClick={handleProfilePhotoUpload}>
                                                        <Avatar size="large" name={name || config.name} src={profilePhoto || camera} type="circle" />
                                                    </Flex>
                                                    <Flex display={{sm: "none", lg: "flex"}} gap="8px">
                                                        <Button
                                                          onClick={handleProfilePhotoUpload}
                                                          loading={loadingUpload}
                                                          type="neutralDark"
                                                          style="solid"
                                                          size="small"
                                                          label={t("common.upload")}
                                                          css={{width: "120px"}}
                                                        />
                                                        <Button
                                                          onClick={handleClearPhoto}
                                                          disabled={!profilePhoto}
                                                          type="danger"
                                                          style="ghost"
                                                          size="small"
                                                          label={t("common.cancel")}
                                                          css={{width: "120px"}}
                                                        />
                                                    </Flex>
                                                    <Flex display={{sm: "flex", lg: "none"}} direction="column" gap="8px" w="100%">
                                                        <Button
                                                          onClick={handleProfilePhotoUpload}
                                                          loading={loadingUpload}
                                                          type="neutralDark"
                                                          style="solid"
                                                          size="small"
                                                          label={t("common.upload")}
                                                          css={{width: "100%"}}
                                                        />
                                                        <Button
                                                          onClick={handleClearPhoto}
                                                          disabled={!profilePhoto}
                                                          type="danger"
                                                          style="ghost"
                                                          size="small"
                                                          label={t("common.cancel")}
                                                          css={{width: "100%"}}
                                                        />
                                                        <Button
                                                          type="neutralDark"
                                                          style="outline"
                                                          size="small"
                                                          label={t("ticket_fares.take_photo")}
                                                          css={{width: "120px", display: "none"}}
                                                        />
                                                    </Flex>
                                                </Flex>
                                            </FormItem>
                                          )}
                                        />
                                    </Flex>

                                    { passTypes &&
                                      <Flex w={{sm: "100%", lg: "65%"}}>
                                          <Controller
                                            name='pass_type'
                                            control={control}
                                            rules={{ required: t("authentication.required_field") }}
                                            render={({ field }) => (
                                              <FormItem required label={t("ticket_fares.select_profile")} error={errors.pass_type}>
                                                  <Select
                                                    {...field}
                                                    options={passTypes}
                                                    background={"white"}
                                                  />
                                              </FormItem>
                                            )}
                                          />
                                      </Flex>
                                    }

                                    { config?.pass_requests?.specific_pass_warning?.includes(pass_type) &&
                                      <Flex
                                        gap="16px"
                                        padding="8px 16px"
                                        bg="#E0EAFF"
                                        border="1px solid #C7D7FE"
                                        rounded="4px"
                                        align="center"
                                        w='100%'
                                      >
                                          <Image w={{sm: "32px", lg: "24px"}} src={infoIconBlue} />
                                          <Text fontSize="14px" color="#444CE7">
                                              <Trans i18nKey="ticket_fares.student_profile_validity_info"/>
                                          </Text>
                                      </Flex>
                                    }


                                    { cardOptions &&
                                      <Flex direction='column' w='100%' gap='8px'>
                                          <Flex w={{sm: "100%", lg: "65%"}} direction="column">
                                              <Controller
                                                name='card_option'
                                                control={control}
                                                rules={{ required: t("authentication.required_field") }}
                                                render={({ field }) => (
                                                  <FormItem required label={t("ticket_fares.card_option")} error={errors.card_option}>
                                                      <Select
                                                        options={cardOptions}
                                                        {...field}
                                                        background={"white"}
                                                      />
                                                  </FormItem>
                                                )}
                                              />
                                          </Flex>
                                          <Text fontSize="14px" color="#475467">
                                              <Trans i18nKey="ticket_fares.vat_included">
                                                  VAT included at the current rate. Check our <Link className="chakra-custom-link" sx={{ textDecoration: 'underline', fontWeight: '600'}} as={ReactLink} to="/privacy-policy" isExternal>Privacy Policy</Link>.
                                              </Trans>
                                          </Text>
                                      </Flex>
                                    }

                                    { card_option === "digital_card" &&
                                      <>
                                          <Flex
                                            gap="16px"
                                            padding="8px 16px"
                                            bg="#E0EAFF"
                                            border="1px solid #C7D7FE"
                                            rounded="4px"
                                            align="center"
                                          >
                                              <Image w={{sm: "64px", lg: "32px"}} src={infoIconBlue} />
                                              <Text fontSize="14px" color="#444CE7">
                                                  <Trans i18nKey="ticket_fares.digital_pass_pick_hub_account_created">
                                                      If you choose a digital pass, you must already have a Pick Hub account created. Download Pick Hub app <Link className="chakra-custom-link" sx={{fontWeight: "600", textDecoration: "underline"}} href="https://pickbyubirider.page.link/downloadpick" target="_blank"><Text textDecoration='underline' fontWeight='600'>here</Text></Link>.
                                                  </Trans>
                                              </Text>
                                          </Flex>
                                          <Controller
                                            name='commitment'
                                            control={control}
                                            rules={{ required: t("authentication.required_field") }}
                                            render={({ field: { value, onChange } }) => (
                                              <FormItem required label={t("ticket_fares.declaration_of_commitment_title")} error={errors.checked_permissions}>
                                                  <Flex direction="column" gap="24px" align="flex-start" w="100%">
                                                      <Flex gap="16px" onClick={() => onChange(!value)} cursor={'pointer'}>
                                                          <Checkbox checked={value} onChange={onChange} />
                                                          <Text fontSize="14px" color="#282828">{t("ticket_fares.declaration_of_commitment_subtitle")}</Text>
                                                      </Flex>
                                                  </Flex>
                                              </FormItem>
                                            )}
                                          />
                                      </>
                                    }

                                    { card_option !== null && pass_type !== null && requiredDocs?.length > 0 &&
                                      <Flex direction="column" gap="24px" width="100%">
                                          { requiredDocs?.map((document) => (
                                            <Controller
                                              name={document}
                                              control={control}
                                              rules={{ required: t("authentication.required_field") }}
                                              render={({ field }) => (
                                                <FormItem required label={document} link={RequirementTemplates[config.operator][document]?.link ?? null}>
                                                    <FileInput
                                                      {...field}
                                                      maxSize={2}
                                                      expanded={false}
                                                      multiple={false}
                                                      allowedExtensions={["pdf", "png", "jpeg", "jpg"]}
                                                    />
                                                </FormItem>
                                              )}
                                            />
                                          ))}
                                      </Flex>
                                    }

                                    <Flex
                                      direction="column"
                                      gap="32px"
                                      padding={{lg: "24px"}}
                                      align="flex-start"
                                      flex="1"
                                      bg={{lg: "white"}}
                                      border={{lg: "1px solid #D3D3D3"}}
                                      borderRadius={{lg: "8px"}}
                                    >
                                        <Controller
                                          name='checked_permissions'
                                          control={control}
                                          rules={{ required: t("authentication.required_field") }}
                                          render={({ field: { value, onChange } }) => (
                                            <FormItem required label={t("ticket_fares.permissions")} error={errors.checked_permissions}>
                                                <Flex direction="column" gap="24px" align="flex-start" w="100%">
                                                    <Text fontSize="14px" color="#667085">{t("ticket_fares.permissions_authorization")}</Text>
                                                    <Flex gap="16px" onClick={() => onChange(!value)} cursor={'pointer'}>
                                                        <Checkbox checked={value} onChange={onChange} />
                                                        <Text fontSize="14px" color="#282828">{t("common.yes")}</Text>
                                                    </Flex>
                                                </Flex>
                                            </FormItem>
                                          )}
                                        />
                                        <Controller
                                          name='checked_terms'
                                          control={control}
                                          rules={{ required: t("authentication.required_field") }}
                                          render={({ field: { value, onChange } }) => (
                                            <FormItem required label={t("ticket_fares.conditions")} error={errors.checked_terms}>
                                                <Flex pr="8px" direction="column" gap="24px" align="flex-start" w="100%">
                                                    <Flex
                                                      direction="column"
                                                      gap="16px"
                                                      w="100%"
                                                      maxH="260px"
                                                      overflowY="auto"
                                                      border="1px solid #D3D3D3"
                                                      padding="18px 24px"
                                                      css={{
                                                          "&::-webkit-scrollbar": {
                                                              width: "8px", // Set the width of the scrollbar
                                                          },
                                                          "&::-webkit-scrollbar-thumb": {
                                                              borderRadius: "8px",
                                                              backgroundColor: "#98A2B3", // Set the color of the scrollbar thumb
                                                          },
                                                          "&::-webkit-scrollbar-track": {
                                                              borderRadius: "8px",
                                                              backgroundColor: "none", // Set the color of the scrollbar track
                                                          },
                                                      }}
                                                    >
                                                        <Text fontSize="12px" color="#282828">{t("ticket_fares.conditions_description_1")}</Text>
                                                        <Text fontSize="12px" color="#282828">{t("ticket_fares.conditions_description_2")}</Text>
                                                        <Text fontSize="12px" color="#282828">{t("ticket_fares.conditions_description_3")}</Text>
                                                        <Text fontSize="12px" color="#282828">{t("ticket_fares.conditions_description_4")}</Text>
                                                        <Text fontSize="12px" color="#282828">{t("ticket_fares.conditions_description_5")}</Text>
                                                        <Text fontSize="12px" color="#282828">{t("ticket_fares.conditions_description_6")}</Text>
                                                        <Text fontSize="12px" color="#282828">{t("ticket_fares.conditions_description_7")}</Text>
                                                        <Text fontSize="12px" color="#282828">{t("ticket_fares.conditions_description_8")}</Text>
                                                        <Text fontSize="12px" color="#282828">{t("ticket_fares.conditions_description_9")}</Text>
                                                        <Text fontSize="12px" color="#282828">{t("ticket_fares.conditions_description_10")}</Text>
                                                        <Text fontSize="12px" color="#282828">{t("ticket_fares.conditions_description_11")}</Text>
                                                        <Text fontSize="12px" color="#282828">{t("ticket_fares.conditions_description_12")}</Text>
                                                        <Text fontSize="12px" color="#282828">{t("ticket_fares.conditions_description_13")}</Text>
                                                        <Text fontSize="12px" color="#282828">{t("ticket_fares.conditions_description_14")}</Text>
                                                        <Text fontSize="12px" color="#282828">{t("ticket_fares.conditions_description_15")}</Text>
                                                        <Text fontSize="12px" color="#282828">{t("ticket_fares.conditions_description_16")}</Text>
                                                        <Text fontSize="12px" color="#282828">{t("ticket_fares.conditions_description_17")}</Text>
                                                        <Text fontSize="12px" color="#282828">{t("ticket_fares.conditions_description_18")}</Text>
                                                        <Text fontSize="12px" color="#282828">{t("ticket_fares.conditions_description_19")}</Text>
                                                        <Text fontSize="12px" color="#282828">{t("ticket_fares.conditions_description_20")}</Text>
                                                        <Text fontSize="12px" color="#282828">{t("ticket_fares.conditions_description_21")}</Text>
                                                        <Text fontSize="12px" color="#282828">{t("ticket_fares.conditions_description_22")}</Text>
                                                        {(config.operator === 'trevo' || config.operator === 'BEJA') &&
                                                        <>
                                                            <Text fontSize="12px" color="#282828">{t("ticket_fares.conditions_description_23")}</Text>
                                                            <Text fontSize="12px" color="#282828">{t("ticket_fares.conditions_description_24")}</Text>
                                                            <Text fontSize="12px" color="#282828">{t("ticket_fares.conditions_description_25")}</Text>
                                                        </>
                                                        }
                                                    </Flex>
                                                    <Flex gap="16px" onClick={() => onChange(!value)} cursor={'pointer'}>
                                                        <Checkbox checked={value} onChange={onChange} />
                                                        <Text fontSize="14px" color="#282828">{t("ticket_fares.conditions_authorization")}</Text>
                                                    </Flex>
                                                </Flex>
                                            </FormItem>
                                          )}
                                        />
                                    </Flex>
                                </Flex>
                            </Flex>
                            <Flex
                              position="relative"
                              align="flex-end"
                              justify="flex-end"
                              gap="20px"
                              mr="-8px"
                              padding={{sm: "24px", lg: "16px 32px"}}
                              bg={isScrolledToBottom ? "#F9F9FB" : "white"}
                              boxShadow={isScrolledToBottom ? "none" : "0px 2px 20px 1px rgba(0, 0, 0, 0.1)"}
                              borderRadius={{lg: "0 0 8px 8px"}}
                              transition="background-color 0.3s ease, box-shadow 0.3s ease"
                            >
                                <Button
                                  onClick={handleStepBack}
                                  style="outline"
                                  type="neutralDark"
                                  label={t("common.back")}
                                  css={{width: "160px"}}
                                />
                                <Button
                                  submit
                                  style="solid"
                                  type="primary"
                                  label={t("common.next")}
                                  css={{width: "160px"}}
                                />
                            </Flex>
                        </Flex>
                    </Flex>
                  }

                  { formStep === 3 &&
                    <Flex direction="column">
                        <Flex
                          position={{sm: "fixed", lg: "inherit"}}
                          padding={{sm: "16px 24px", lg: "16px 32px"}}
                          zIndex="5"
                          bg="white"
                          gap="16px"
                          direction="column"
                          w="100%"
                          boxShadow="0px 2px 20px 1px rgba(0, 0, 0, 0.1)"
                          borderRadius={{lg: "8px 8px 0 0"}}
                        >
                            <Flex align="flex-start" w="100%">
                                <Flex justify="center" align="center" w="100%">
                                    <Text fontWeight="700" fontSize={{sm: "24px", lg: "20px"}} color="#001D20">
                                        {t("ticket_fares.order_pass")}
                                    </Text>
                                </Flex>
                                <Flex justify="flex-end" align="flex-end">
                                    <Flex as="button" onClick={handleCloseForm}>
                                        <Image src={closeButton} />
                                    </Flex>
                                </Flex>
                            </Flex>

                            <Flex borderRadius={{lg: "8px"}} gap="16px" justify="center" align="center">
                                <Flex
                                  direction={{sm: "column", lg: "row"}}
                                  gap="16px"
                                  align="center"
                                  justify="flex-start"
                                  as="button"
                                  onClick={() => handleStepNext(1)}
                                >
                                    <Flex
                                      justify="center"
                                      align="center"
                                      padding="8px 12px"
                                      w="32px"
                                      h="32px"
                                      border={formStep !== 1 && (name && email && address && postal_code && city && phone && birthdate && card_id) ? "1px solid #98A2B3" : null}
                                      bg={formStep === 1 ? config.mainColor : (name && email && address && postal_code && city && phone && birthdate && card_id) ? "white" : "#CACFD8"}
                                      borderRadius="100px"
                                    >
                                        <Text fontSize="20px" fontWeight="600" color={formStep === 1 ? "white" : "#667085"}>1</Text>
                                    </Flex>
                                    <Text fontSize="14px" fontWeight={formStep === 1 ? "700" : "400"} color="#282828" textAlign={{sm: "center", md: "center", lg: "left", xl: "left"}}>{t("ticket_fares.enter_information")}</Text>
                                </Flex>
                                <Divider display={{sm: "none", lg: "flex"}} maxW="35px" border="1px solid rgba(40, 40, 40, 0.25)" />
                                <Flex
                                  direction={{sm: "column", lg: "row"}}
                                  gap="16px"
                                  align="center"
                                  justify="center"
                                  as="button"
                                  onClick={() => handleStepNext(2)}
                                >
                                    <Flex
                                      justify="center"
                                      align="center"
                                      padding="8px 12px"
                                      w="32px"
                                      h="32px"
                                      border={formStep !== 2 && (profilePhoto && card_option && pass_type && checked_terms && checked_permissions) ? "1px solid #98A2B3" : null}
                                      bg={formStep === 2 ? config.mainColor : (profilePhoto && card_option && pass_type && checked_terms && checked_permissions) ? "white" : "#CACFD8"}
                                      borderRadius="100px"
                                    >
                                        <Text fontSize="20px" fontWeight="600" color={formStep === 2 ? "white" : "#667085"}>2</Text>
                                    </Flex>
                                    <Text fontSize="14px" fontWeight={formStep === 2 ? "700" : "400"} color="#282828" textAlign={{sm: "center", md: "center", lg: "left", xl: "left"}}>{t("ticket_fares.select_pass_type")}</Text>
                                </Flex>
                                <Divider display={{sm: "none", lg: "flex"}} maxW="35px" border="1px solid rgba(40, 40, 40, 0.25)" />
                                <Flex
                                  direction={{sm: "column", lg: "row"}}
                                  gap="16px"
                                  align="center"
                                  justify="center"
                                >
                                    <Flex
                                      justify="center"
                                      align="center"
                                      padding="8px 12px"
                                      w="32px"
                                      h="32px"
                                      border={formStep !== 3 && checked_sales ? "1px solid #98A2B3" : null}
                                      bg={formStep === 3 ? config.mainColor : checked_sales ? "white" : "#CACFD8"}
                                      borderRadius="100px"
                                    >
                                        <Text fontSize="20px" fontWeight="600" color={formStep === 3 ? "white" : "#667085"}>3</Text>
                                    </Flex>
                                    <Text fontSize="14px" fontWeight={formStep === 3 ? "700" : "400"} color="#282828" textAlign={{sm: "center", md: "center", lg: "left", xl: "left"}}>{t("ticket_fares.purchase_travel_card")}</Text>
                                </Flex>
                            </Flex>
                        </Flex>

                        { props.errorID && errorIDs.includes(props.errorID) &&
                          <TransactionErrorBanner errorID={props.errorID} isVisible={isVisible} />
                        }

                        <Flex
                          w="100%"
                          borderRadius={{lg: "0 0 8px 8px"}}
                          direction="column"
                          bg="#F9F9FB"
                          flex="1"
                          mt={{sm: "170px", lg: "0"}}
                          overflow="hidden"
                          as="form"
                          onSubmit={handleSubmit(handlePay)}
                        >
                            <Flex
                              direction="column"
                              gap="24px"
                              align="flex-start"
                              padding={{sm: "32px 16px 32px 24px", lg: "24px 32px"}}
                              h={props.errorID ? {lg: "calc(100vh - 354px)"} : {lg: "calc(100vh - 254px)"}}
                              w="100%"
                              overflowY={{lg: "scroll"}}
                              css={{
                                  "&::-webkit-scrollbar": {
                                      width: "8px", // Set the width of the scrollbar
                                  },
                                  "&::-webkit-scrollbar-thumb": {
                                      borderRadius: "8px",
                                      backgroundColor: "#98A2B3", // Set the color of the scrollbar thumb
                                  },
                                  "&::-webkit-scrollbar-track": {
                                      borderRadius: "8px",
                                      backgroundColor: "none", // Set the color of the scrollbar track
                                  },
                              }}
                              onScroll={handleScroll}
                            >
                                <Flex
                                  direction="column"
                                  gap="24px"
                                  align="flex-start"
                                  w="100%"
                                  bg="white"
                                  padding="16px 24px 21px"
                                  border="1px solid #E6E6E6"
                                  borderRadius="8px"
                                >
                                    <Flex direction="column" gap="8px" align="flex-start" w="100%">
                                        <Text fontSize="16px" fontWeight="600" color="#98A2B3">{t("ticket_fares.summary")}</Text>
                                        <Text fontSize="24px" fontWeight="700" color="#1D2939">{t("ticket_fares.travel_card_request")}</Text>
                                    </Flex>
                                    <Flex
                                      gap="16px"
                                      padding="8px 16px"
                                      align="center"
                                      bg="#FEECAA"
                                      border="1px solid #FDE180"
                                      borderRadius="4px"
                                      w="100%"
                                    >
                                        <Image src={alertIcon} />
                                        <Text fontSize="14px" color="#91600E">{t("ticket_fares.alert_request_only_for_card")}</Text>
                                    </Flex>
                                    <Flex direction="column" gap="8px" align="flex-start" w="100%">
                                        <Flex justify="space-between" w="100%">
                                            <Text fontSize="14px" fontWeight="700" color="#1D2939">{t("ticket_fares." + card_option)}</Text>
                                            <Text fontSize="14px" color="#1D2939">€{(data?.total / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })}</Text>
                                        </Flex>
                                        <Text fontSize="14px" color="#1D2939">{pass_type?.split("_").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")}</Text>
                                    </Flex>
                                    <Divider border="1px solid #CACFD8" />
                                    <Flex justify="space-between" w="100%">
                                        <Flex align="flex-start" direction="column" gap="4px">
                                            <Text fontSize="30px" fontWeight="700" color="#1D2939">{t("widget_fare_search.total")}</Text>
                                            <Text fontSize="14px" color="#667085">{t("widget_fare_search.taxes_included")}</Text>
                                        </Flex>
                                        <Text fontSize="30px" fontWeight="700" color="#1D2939">€{(data?.total / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })}</Text>
                                    </Flex>
                                </Flex>
                                <Controller
                                  name='checked_sales'
                                  control={control}
                                  rules={{ required: t("authentication.required_field") }}
                                  render={({ field: { value, onChange } }) => (
                                    <FormItem required label={t("widget_fare_search.sale_terms_and_conditions")} error={errors.checked_sales}>
                                        <Flex direction="column" gap="16px" align="flex-start" justify="flex-start">
                                            <Flex gap="16px" onClick={() => onChange(!value)} cursor={'pointer'}>
                                                <Checkbox checked={value} onChange={onChange} />
                                                <Text fontSize="14px" color="#282828">
                                                    <Trans i18nKey="widget_fare_search.sale_terms_and_conditions_tickbox">
                                                        I agree with the sale terms and conditions. <Link className="chakra-custom-link" sx={{ textDecoration: 'underline', fontWeight: '600'}} as={ReactLink} to="/privacy-policy" isExternal>Read more</Link>.
                                                    </Trans>
                                                </Text>
                                            </Flex>
                                        </Flex>
                                    </FormItem>
                                  )}
                                />
                            </Flex>
                            <Flex
                              w="100%"
                              position="relative"
                              align="flex-end"
                              justify="flex-end"
                              gap="20px"
                              padding={{sm: "24px", lg: "16px 32px"}}
                              bg={isScrolledToBottom ? "#F9F9FB" : "white"}
                              boxShadow={isScrolledToBottom ? "none" : "0px 2px 20px 1px rgba(0, 0, 0, 0.1)"}
                              borderRadius={{lg: "0 0 8px 8px"}}
                              transition="background-color 0.3s ease, box-shadow 0.3s ease"
                            >
                                <Button
                                  onClick={handleStepBack}
                                  style="outline"
                                  type="neutralDark"
                                  label={t("common.back")}
                                  css={{width: "160px"}}
                                />
                                <Button
                                  submit
                                  loading={loading}
                                  disabled={!isVisible}
                                  style="solid"
                                  type="primary"
                                  label={ data?.total === 0 ? t("common.submit") : t("ticket_fares.pay")}
                                  css={{width: "160px"}}
                                />
                            </Flex>
                        </Flex>
                    </Flex>
                  }
              </Flex>
          </ModalContent>

          <ErrorRequestModal isOpen={isOpen} onClose={onClose} />
      </Modal>
    )
}
