import {
  Button,
  FormItem, FileInput, Select,
} from '@ubirider/pick-component-library'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { Flex, Text } from '@chakra-ui/react'
import { UserProfileInfo } from '../UserProfileInfo'
import { DateTime } from 'luxon'
import { RequirementTemplates } from '../../../../consts/templates'
import config from '../../../../config/configLoader'
import { handleChangeProfile } from '../../../../api/requests'
import { useCustomToast } from '../../../../hooks/useToast'
import { ProfileStatus } from '../../../../consts/status'

export const ChangeUserProfile = ({ userProfile, prices, setLoading, loading, handleScroll, isScrolledToBottom, onClose }) => {
  const { t } = useTranslation()
  const showCustomToast = useCustomToast()

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useFormContext()

  const profile = watch('profile')

  const profiles = useMemo(() => {
    const today = DateTime.now()
    const age = Math.floor(today.diff(DateTime.fromISO(userProfile.birthdate), 'years').years)

    return prices.filter(ticket => !(ticket.minAge !== null && age < ticket.minAge) &&
      !(ticket.maxAge !== null && age > ticket.maxAge) &&
      (userProfile.profiles[0].status !== ProfileStatus.DEACTIVATED && (ticket?.variationName ?
        ticket.variationName !== userProfile.profiles[0].profileName
        : ticket.compoundName !== userProfile.profiles[0].profileName)
      ))
      .map(ticket => ({
        labelComponent: (
          <Text fontSize="14px" fontWeight="600" color="#424D5F">
            {ticket?.variationName ? ticket.variationName : ticket.compoundName}
          </Text>
        ),
        value: ticket?.profileID
      }))
  }, [])

  const newRequiredDocs = useMemo(() => {
    if (profile) return prices.find(ticket => ticket.profileID === profile).requiredDocuments ?? []
    return []
  }, [profile])

  const onSubmitChangeProfile = async data => {
    setLoading(true)
    try {
      const docFiles = []
      if (newRequiredDocs.length) newRequiredDocs.forEach(doc => docFiles.push(data[doc]))
      await handleChangeProfile({
        customerID: userProfile.customerID,
        profileID: data.profile,
      }, docFiles)
      showCustomToast('success', t("ticket_fares.profile_successfully_submitted"))
      onClose()
    } catch (e) {
      console.log(e)
      showCustomToast('danger', t("ticket_fares.profile_request_declined"))
    }
    setLoading(false)
  }

  return (
    <Flex
      as='form'
      onSubmit={handleSubmit(onSubmitChangeProfile)}
      w='100%'
      direction='column'
      borderRadius={{ lg: '0 0 8px 8px' }}
      flex='1'
    >
      <Flex
        direction='column'
        gap='24px'
        bg='white'
        w='100%'
        padding={{ sm: '16px 32px 32px', lg: '12px 32px 16px 32px' }}
        h={newRequiredDocs.length > 0 ? { lg: 'calc(100vh - 344px)' } : '100%'}
        overflowY={{ lg: 'scroll' }}
        onScroll={handleScroll}
      >
        <UserProfileInfo userProfile={{...userProfile.profiles[0], ...userProfile}} showInline={false} />

        <Flex w={{ sm: '100%', lg: '50%' }}>
          <Controller
            name='profile'
            control={control}
            rules={{ required: t('authentication.required_field') }}
            render={({ field }) => (
              <FormItem required label={t('ticket_fares.new_profile')} error={errors.profile}>
                <Select {...field} options={profiles} />
              </FormItem>
            )}
          />
        </Flex>

        {newRequiredDocs.length > 0 && (
          <Flex direction='column' gap='24px' width='100%'>
            {newRequiredDocs?.map(document => (
              <Controller
                name={document}
                control={control}
                rules={{ required: t('authentication.required_field') }}
                render={({ field }) => (
                  <FormItem
                    required
                    label={document}
                    link={RequirementTemplates[config.operator][document]?.link ?? null}
                    error={errors[document]}
                  >
                    <FileInput
                      {...field}
                      maxSize={2}
                      expanded={false}
                      multiple={false}
                      allowedExtensions={['pdf', 'png', 'jpeg', 'jpg']}
                    />
                  </FormItem>
                )}
              />
            ))}
          </Flex>
        )}
      </Flex>

      <Flex
        position='relative'
        align='flex-end'
        justify='flex-end'
        w='100%'
        padding={{ sm: '0 32px 32px', lg: '16px 32px 32px 32px' }}
        bg={'white'}
        boxShadow={isScrolledToBottom ? { lg: 'none' } : { lg: '0px 2px 20px 1px rgba(0, 0, 0, 0.1)' }}
        borderRadius={{ lg: '0 0 8px 8px' }}
        transition='background-color 0.3s ease, box-shadow 0.3s ease'
      >
        <Button
          label={t('common.submit')}
          type={'primary'}
          style={'solid'}
          submit
          loading={loading}
          css={{ minWidth: 128 }}
        />
      </Flex>
    </Flex>
  )
}
